.card {
    border: 1px solid white;
    margin: 20px 0px;
    position: relative;
    padding: 15px 15px;
    background-color: var(--col-1);
}

.buttons {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.buttons button {
    height: 100%;
    margin-left: 10px;
    width: 55px;
}

.card > h3, .card > div {
    display: inline-block;
    vertical-align: middle;
}

.card > h3 {
    width: 125px;
}

.card > div {
    font-size: 12px;
}

.card .counter {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
}

.card h3 {
    margin: 0;
    font-size: 18px;
}

.sub-title {
    font-weight: normal;
    font-size: 17px;
    margin-top: 2px;
}

.symbol {
    font-size: 25px;
    position: relative;
    top: 3px;
}