.Modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  > .overlay {
    position: absolute;
    background: black;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.4;
    z-index: 4;
  }

  > .dialog-container {
    max-width: 600px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .dialog {
    background: #333;
    padding: 15px;
    display: flex;
    max-width: 400px;
    flex-direction: column;
    box-shadow: 0 0 5px 3px rgba(255,255,255,.15);
    gap: 15px;
    margin-top: 90px;

    > .modal-header {
      font-weight: bold;
    }

    > .footer {
      display: flex;
      justify-content: center;
      gap: 10px
    }

    input {
      padding: 10px;
      width: 230px
    }
  }
}