.CountSeeds {
  input {
    width: 100%;
    font-size: 18px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
    display: block;
  }

  div.result {
    font-size: 16px;
    margin-bottom: 20px;
  }

  div.error {
    color: #fd6f6f;
    font-weight: bold;
  }
}